@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
	background-color: #faf7f9 !important;
	padding: 0;
	margin: 0;
	font-family:
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		Fira Sans,
		Droid Sans,
		Helvetica Neue,
		sans-serif;
}

a {
	color: inherit;
	text-decoration: none !important;
	-webkit-tap-highlight-color: transparent;
}

* {
	box-sizing: border-box;
}

.mantine-AppShell-root {
	max-width: calc(100% - 0px);
}

i {
	font-size: 20px;
}

.mantine-Stepper-root .mantine-1h12jut {
	position: fixed;
	width: 100%;
	max-width: 488px;
	z-index: 9999;
	padding: 12px 16px;
	background: white;
	margin-top: -16px;
}

.mantine-Stepper-root .mantine-evwtda {
	padding-top: 43px !important;
}

.gsi-material-button {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-webkit-appearance: none;
	background-color: #f2f2f2;
	background-image: none;
	border: none;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	border: 1px solid #e4e1e3;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #1f1f1f;
	cursor: pointer;
	font-family: "Roboto", arial, sans-serif;
	font-size: 14px;
	height: 40px;
	letter-spacing: 0.25px;
	outline: none;
	overflow: hidden;
	padding: 0 12px;
	position: relative;
	text-align: center;
	-webkit-transition:
		background-color 0.218s,
		border-color 0.218s,
		box-shadow 0.218s;
	transition:
		background-color 0.218s,
		border-color 0.218s,
		box-shadow 0.218s;
	vertical-align: middle;
	white-space: nowrap;
	width: auto;
	max-width: 400px;
	min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
	height: 20px;
	margin-right: 12px;
	min-width: 20px;
	width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
	-webkit-align-items: center;
	align-items: center;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	height: 100%;
	justify-content: space-between;
	position: relative;
	width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
	-webkit-flex-grow: 1;
	flex-grow: 1;
	font-family: "Roboto", arial, sans-serif;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
	-webkit-transition: opacity 0.218s;
	transition: opacity 0.218s;
	bottom: 0;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.gsi-material-button:disabled {
	cursor: default;
	background-color: #ffffff61;
}

.gsi-material-button:disabled .gsi-material-button-state {
	background-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
	opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
	opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
	background-color: #001d35;
	opacity: 12%;
	outline: none;
	box-shadow: none;
}

.gsi-material-button:not(:disabled):hover {
	-webkit-box-shadow:
		0 1px 2px 0 rgba(60, 64, 67, 0.3),
		0 1px 3px 1px rgba(60, 64, 67, 0.15);
	box-shadow:
		0 1px 2px 0 rgba(60, 64, 67, 0.3),
		0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
	background-color: #001d35;
	opacity: 8%;
}

.masonry-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* Dua kolom */
	gap: 16px; /* Jarak antar elemen */
	grid-auto-rows: auto; /* Tinggi baris mengikuti konten */
	grid-auto-flow: dense; /* Masonry-style: item terisi rapat */
}

.masonry-item {
	display: block;
	break-inside: avoid; /* Hindari item terpecah */
	overflow: hidden;
	padding: 0;
	height: auto; /* Tinggi kartu mengikuti tinggi gambar */
}

.masonry-item-img {
	display: block;
	width: 100%;
	height: auto; /* Menjaga gambar tetap dalam rasio */
}

.masonry-item .mantine-Card-section {
	padding: 0; /* Hilangkan padding default Mantine */
}

/* .mantine-1h12jut {
  position: fixed;
  z-index: 9999;
  background-color: white;
  width: calc(100% - 32px);
  padding: 10px 0;
  margin-top: -16px;
} */

/* .mantine-AppShell-body{
  height: calc(100vh - 26px);
} */
